// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

import "../stylesheets/application.scss";
import "../stylesheets/scaffolds.scss";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
  // Used in Transaction Index view to remove empty input and streamline submitted url
  $("#filter_form").on("submit", function() {
    $(this).find(':input').filter(function() { return !this.value; }).attr('disabled', 'disabled');
  });

  // Used in Transactions Index view to clean filter form from any previous selection
  $("#filter_clear").on("click", function() {
    $(this).parents('form:first').find(':input:not([type=hidden])').val('');
  });

  // Used in Transaction Lists Upload form to fix bug in Bootstrap
  $('#transactions_file').on("change", function() {
    let fileName = $(this).val().split("\\").pop();
    $(this).next(".custom-file-label").html(fileName);
  })

  // Used in New Transaction view to pre-select today's date and pre-select a transaction type
  if ($('#transaction_transaction_type_id').length) {
    // Pre-select today's date if the form is "New" and not "Edit"
    if ($('#transaction_transaction_date').val() == "")
      $('#transaction_transaction_date').val(new Date().toLocaleDateString('en-CA'));

    // Get "type" param value
    let searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has('type')) return;
    var transactionTypeToSelect = searchParams.get('type').toLowerCase();

    // Select the Transaction Type if it matches the passed param
    $('#transaction_transaction_type_id option').filter(function() {
      return ($(this).text().toLowerCase() == transactionTypeToSelect);
    }).prop('selected', true);
  }

  // Used in New Transaction view to copy value from populated amount to empty amount
  $('#transaction_amount').on("change", function() {
    if ($('#transaction_amount_to').val() == "")
      $('#transaction_amount_to').val($(this).val());
  })
  $('#transaction_amount_to').on("change", function() {
    if ($('#transaction_amount').val() == "")
      $('#transaction_amount').val($(this).val());
  })
})
